/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Image } from 'react-bootstrap';

import Brush from 'assets/brushes/services.png';

import useDrawerProvider from 'common/hooks/useDrawerProvider';
import ServicesDetails from 'components/desktop/services-details/ServicesDetails';
import styles from './ServicesSection.module.scss';

function ServicesSection({ width }) {
  const { dispatch } = useDrawerProvider();

  const openServices = slide => {
    dispatch({
      type: 'OPEN_DRAWER',
      Content: ServicesDetails,
      item: slide,
    });
  };

  return (
    <div className={styles.container} style={{ width: width || 'inherit' }}>
      <ul className="list-unstyled center-block">
        <li
          role="presentation"
          onClick={() => {
            openServices(0);
          }}
        >
          Prophylaxe und professionelle Zahnreinigung
        </li>
        <li
          role="presentation"
          onClick={() => {
            openServices(1);
          }}
        >
          Kariesbehandlung
        </li>
        <li
          role="presentation"
          onClick={() => {
            openServices(2);
          }}
        >
          Endodontie <span>Wurzelkanalbehandlung</span>
        </li>
        <li
          role="presentation"
          onClick={() => {
            openServices(3);
          }}
        >
          Parodontologie <span>Zahnfleischbehandlung</span>
        </li>
        <li
          role="presentation"
          onClick={() => {
            openServices(4);
          }}
        >
          Prothetik <span>Zahnersatz</span>
        </li>
        <li
          role="presentation"
          onClick={() => {
            openServices(5);
          }}
        >
          Implantation
        </li>
        <li
          role="presentation"
          onClick={() => {
            openServices(6);
          }}
        >
          Ästhetische Zahnheilkunde <span>Zahnaufhellung und Veneers</span>
        </li>
        <li
          role="presentation"
          onClick={() => {
            openServices(7);
          }}
        >
          Kinderbehandlung
        </li>
        <li
          role="presentation"
          onClick={() => {
            openServices(0);
          }}
        >
          <Image
            fluid
            src={Brush}
            className={styles.brush}
            alt="Services brush"
          />
        </li>
      </ul>
    </div>
  );
}

export default memo(ServicesSection);
