/* eslint-disable react/prop-types,react/no-array-index-key,no-return-assign */
import React, { memo } from 'react';
import { Col, Image } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';

import Brush from 'assets/brushes/contact.png';

import styles from './ContactDetails.module.scss';

function ContactDetails({ width, children }) {
  const lat = 48.77831;
  const lng = 9.1606;

  const handleApiLoaded = (map, maps) => {
    new maps.Marker({
      position: {
        lat,
        lng,
      },
      map,
    });
  };

  return (
    <Col md={7} className={styles.container}>
      <div className={styles.details} style={{ width: width || 'inherit' }}>
        <h1>anfahrt</h1>
        <Image
          fluid
          src={Brush}
          className={styles.brush}
          alt="Services details brush"
        />
        <div className={styles.description}>
          Johannesstraße 47b 70176 Stuttgart-West
        </div>
      </div>

      <div className={styles.canvas}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyBOamm8L87tX1UWJ2yGg7gkQQ-W0AhZb_w',
          }}
          defaultCenter={{
            lat,
            lng,
          }}
          defaultZoom={15}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          yesIWantToUseGoogleMapApiInternals
        />
      </div>

      {children}
    </Col>
  );
}

export default memo(ContactDetails);
