/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Image } from 'react-bootstrap';

import Opened from 'assets/status/opened.png';
import Closed from 'assets/status/closed.png';
import Vacation from 'assets/status/vacation.png';

import styles from './StatusSection.module.scss';

/**
 * Public holidays expressed in form 'dd.mm.yyyy'
 * @type {string[]}
 */
const publicHolidays = ['25.12.2020'];

/**
 * Vacation days expressed in form 'dd.mm.yyyy'
 * @type {*[]}
 */
const vacation = [
  '19.07.2020',
  '21.07.2020',
  '22.07.2020',
  '23.07.2020',
  '24.07.2020',
];

/**
 * Determines status of the current day
 */
const determineStatus = () => {
  const today = new Date();
  const currentDay = today.getDay();
  const currentDate = today.toLocaleDateString('de-DE', {
    day: 'numeric',
    month: '2-digit',
    year: 'numeric',
  });

  if (publicHolidays.includes(currentDate)) {
    return Closed;
  }

  if (vacation.includes(currentDate)) {
    return Vacation;
  }

  if (currentDay === 6 || currentDay === 0) {
    return Closed;
  }
  return Opened;
};

const status = determineStatus();

function StatusSection() {
  return (
    <div className={styles.container}>
      <Image
        fluid
        src={status}
        className={`${styles.image} ${status === Vacation ? styles.shake : ''}`}
        alt="status"
      />
    </div>
  );
}

export default memo(StatusSection);
