import { useEffect } from 'react';

const useTimeout = (callback, timeout) => {
  useEffect(() => {
    const timer = setTimeout(callback, timeout);

    return () => {
      clearTimeout(timer);
    };
  }, [callback, timeout]);
};

export default useTimeout;
