/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Image } from 'react-bootstrap';

import useDrawerProvider from 'common/hooks/useDrawerProvider';
import RJ from 'assets/rj/logo-blue.png';

import Imprint from 'components/desktop/imprint/Imprint';
import Privacy from 'components/desktop/privacy/Privacy';

import styles from './FooterSection.module.scss';

function FooterSection({ element }) {
  const { dispatch } = useDrawerProvider();

  const openImprint = () => {
    dispatch({
      type: 'OPEN_DRAWER',
      Content: Imprint,
    });
  };

  const openPrivacy = () => {
    dispatch({
      type: 'OPEN_DRAWER',
      Content: Privacy,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.meta}>
        <div role="presentation" onClick={openImprint}>
          Impressum
        </div>
        <div role="presentation" onClick={openPrivacy}>
          Datenschutz
        </div>
      </div>
      <Image
        ref={element}
        fluid
        src={RJ}
        className={styles.logo}
        alt="RJ logo"
      />
    </div>
  );
}

export default memo(FooterSection);
