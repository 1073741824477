/* eslint-disable react/prop-types */
import React, { forwardRef, memo } from 'react';
import { Image } from 'react-bootstrap';

import Logo from 'assets/logo/32pro-logo-blue.png';

import styles from './LogoSection.module.scss';

function LogoSection() {
  return (
    <div className={styles.container}>
      <Image fluid src={Logo} className={styles.logo} alt="32 pro logo" />
    </div>
  );
}

export default memo(forwardRef((_, ref) => <LogoSection element={ref} />));
