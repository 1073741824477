/* eslint-disable react/prop-types */
import React, { memo, useEffect } from 'react';

import styles from './JamedaSection.module.scss';

function JamedaSection() {
  useEffect(() => {
    const script = document.createElement('script');

    script.src =
      'https://cdn1.jameda-elements.de/widgets/siegel/81338841_1/895462/';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div id="jameda-widget-container895462" className={styles.jameda}>
        <div id="jambw" className={styles.jambw}>
          <div className={styles.loading}>Bewertung wird geladen...</div>
        </div>
        <div className={styles.content}>
          <a
            href="https://www.jameda.de/stuttgart/zahnaerzte/parodontologen/fachgebiet/?utm_content=BWlast&amp;utm_source=Kunden-Homepages&amp;utm_medium=Badges&amp;utm_term=81338841&amp;utm_campaign=Badges"
            className={`${styles.link} jam_link_check`}
            target="_blank"
          >
            <strong>Parodontologen</strong>
            <br />
            in Stuttgart
          </a>
        </div>
      </div>
    </div>
  );
}

export default memo(JamedaSection);
