/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Image } from 'react-bootstrap';

import Calendar from 'assets/appointment/calendar.jpg';
import Appointment from 'assets/appointment/appointment.png';

import styles from './AppointmentSection.module.scss';

const openPatientPortal = () => {
  window.open('https://za32pro.termin.dampsoft.net/patientenportal/', '_blank');
};

function AppointmentSection() {
  return (
    <div className={styles.container}>
      <Image
        fluid
        src={Calendar}
        className={styles.image}
        onClick={openPatientPortal}
        alt="calendar"
      />
      <Image
        fluid
        src={Appointment}
        className={styles.image}
        onClick={openPatientPortal}
        alt="appointment"
      />
    </div>
  );
}

export default memo(AppointmentSection);
