/* eslint-disable react/prop-types,react/no-array-index-key,no-return-assign */
import React, { memo, useState, useRef, useEffect } from 'react';
import { Col, Image } from 'react-bootstrap';

import Brush from 'assets/brushes/team.png';
import Anna from 'assets/team/anna.jpg';
import Helmina from 'assets/team/helmina.jpg';
import Max from 'assets/team/max.jpg';
import Sonja from 'assets/team/sonja.png';
import Nina from 'assets/team/nina.jpg';

import Slider from 'react-slick';
import styles from './TeamDetails.module.scss';

const team = [
  {
    image: Anna,
    title: <h3>Anna Efremova</h3>,
    description: (
      <div className={styles.description}>
        <ul>
          <li>Auszubildende</li>
          <li>Behandlungsassistenz</li>
          <li>Rezeption</li>
        </ul>
      </div>
    ),
  },
  {
    image: Helmina,
    title: <h3>Helmina Zulovic</h3>,
    description: (
      <div className={styles.description}>
        <ul>
          <li>Auszubildende</li>
          <li>Behandlungsassistenz</li>
          <li>Rezeption</li>
        </ul>
      </div>
    ),
  },
  {
    image: Max,
    title: <h3>Maksimilian Gapontsev</h3>,
    description: (
      <div className={styles.description}>
        <ul className={styles.justify}>
          <li>Zahnarzt - Praxisinhaber</li>
          <li>1997-2001 Studium der Zahnmedizin, Universität St. Petersburg</li>
          <li>2004-2010 Studium der Zahnmedizin, Universität Ulm</li>
          <br />
          <li>Berufliche Stationen:</li>
          <li>
            2011-2012 Assistentzzahnarzt in der Zahnarztpraxis Dr.Gerd Hase,
            Stuttgart.
          </li>
          <li>
            2013-2015 Assistenzzahnarzt in der Zahnarztpraxis Rita Kozaeva,
            Stuttgart.
          </li>
          <li>
            seit 2015 Niederlassung als selbständiger Zahnarzt in Stuttgart
          </li>
          <br />
          <li>Weiterbildung:</li>
          <li>2011 Ästhetische Restaurationen im Frontzahnbereich</li>
          <li>2014 Curriculum Parodontologie</li>
        </ul>
      </div>
    ),
  },
  {
    image: Sonja,
    title: <h3>Sofia Gapontseva</h3>,
    description: (
      <div className={styles.description}>
        <ul>
          <li>Zahntechnikerin</li>
          <li>Leiterin des Praxislabors</li>
        </ul>
      </div>
    ),
  },
  {
    image: Nina,
    title: <h3>Nina Wotinzew</h3>,
    description: (
      <div className={styles.description}>
        <ul>
          <li>Zahnmedizinische Fachangestellte</li>
          <li>Behandlungsassistenz</li>
          <li>Professionelle Zahnreinigung</li>
          <li>Rezeption</li>
          <li>Abrechnung</li>
          <li>Qualitätsmanagement</li>
        </ul>
      </div>
    ),
  },
];

function TeamDetails({ width, item, children }) {
  const [sliders, setSliders] = useState({
    mainSlider: null,
    descriptionSlider: null,
  });

  const mainSliderRef = useRef();
  const descriptionSliderRef = useRef();

  useEffect(() => {
    setSliders({
      mainSlider: mainSliderRef.current,
      descriptionSlider: descriptionSliderRef.current,
    });
  }, [mainSliderRef, descriptionSliderRef]);

  const { mainSlider, descriptionSlider } = sliders;

  const nextSlide = () => {
    mainSlider.slickNext();
    descriptionSlider.slickNext();
  };

  return (
    <Col md={7} className={styles.container}>
      <div className={styles.details} style={{ width: width || 'inherit' }}>
        <h1>unser team</h1>
        <Image
          fluid
          src={Brush}
          className={styles.brush}
          alt="Team details brush"
        />
        <Slider
          dots
          fade
          infinite
          autoplay={false}
          draggable={false}
          arrows={false}
          ref={s => {
            mainSliderRef.current = s;
          }}
          initialSlide={item}
          className={styles.carousel}
        >
          {team.map((member, index) => (
            <div
              role="presentation"
              key={index}
              onClick={nextSlide}
              className={styles.member}
            >
              <div>
                <Image
                  fluid
                  src={member.image}
                  className={styles.brush}
                  alt="Member photo"
                />
                {member.title}
              </div>
            </div>
          ))}
        </Slider>

        <Slider
          fade
          infinite
          dots={false}
          autoplay={false}
          draggable={false}
          arrows={false}
          initialSlide={item}
          ref={s => {
            descriptionSliderRef.current = s;
          }}
          className={styles['description-carousel']}
        >
          {team.map((member, index) => (
            <div role="presentation" key={index} className={styles.member}>
              <div>{member.description}</div>
            </div>
          ))}
        </Slider>
      </div>

      {children}
    </Col>
  );
}

export default memo(TeamDetails);
