/* eslint-disable react/prop-types,react/no-array-index-key */
import React, { memo } from 'react';
import { Image } from 'react-bootstrap';
import Slider from 'react-slick';

import Brush from 'assets/brushes/tipps.png';

import styles from './AdvicesSection.module.scss';

const advices = [
  'Kommen Sie zweimal jährlich zur Vorsorgeuntersuchung.',
  'Lassen Sie mindestens einmal jährlich die professionelle Zahnreinigung durchführen.',
  'Benutzen Sie Zahnseide bzw. Zwischenraumbürstchen.',
  'Wechseln Sie Ihre Zahnbürste alle 8 Wochen.',
  'Nehmen Sie eine Zahnbürste mit kurzem Bürstenkopf für die Backenzähne.',
  'Putzen Sie Ihre Zähne mindestens zweimal täglich.',
  'Mit der elektrischen Zahnbürste bekommen Sie schneller ein gutes Ergebnis als mit der Handzahnbürste.',
  'Verwenden Sie fluoridhaltige Zahnpasta.',
  'Achten Sie auf zahngesunde Ernährung z. B. fluoridiertes Salz, keine säurehaltigen Getränke oder Süßigkeiten.',
  'Verwenden Sie eine weiche Zahnbürste für das Zahnfleisch, das muss beim Putzen mitmassiert werden.',
  'Putzen Sie die Zähne nicht direkt nach dem Essen. Dies kann zu Erosion führen.',
  'Putzen Sie die Zähne nach dem Frühstück.',
  'Führen Sie richtige Putztechnik durch in der Zeit von mindestens 3 Minuten.',
  'Wenden Sie Superfloss bei Kronen oder Brückengliedern an.',
  'Verwenden Sie eine Mundspülung bzw. Munddusche.',
  'Tragen Sie einmal in der Woche Elmex Gelee auf die Zähne mit einem Wattestäbchen auf.',
  'Individualprophylaxe bei Kindern ab 6-17 Jahren wird von der Kasse übernommen.',
  'Ab dem 12. Lebensjahr kann ein Bonusheft ausgestellt werden.',
  'Als Kassenleistung gilt bei Kindern und Jugendlichen Versiegelung der Backenzähne.',
  'Besuchen Sie Ihren Zahnarzt regelmäßig, damit der Bonus nicht verfällt.',
  'Man kann alle 2 Jahre Röntgenaufnahmen machen um Sekundärkaries auszuschließen.',
  'Reinigen Sie täglich den abnehmbaren Zahnersatz.',
  'Legen Sie Zahnprothesen auf keinen Fall in Reinigungslösungen, Kunststoff wird mit der Zeit porös und brüchig.',
  'Wenn Sie Verspannungen im Nacken oder der Kaumuskulatur merken, fragen Sie den Zahnarzt nach einer Bissschiene.',
  'Zahnprothesen bzw. Aufbissbehelfs mit Zahnbürste und Zahnpasta reinigen.',
  'Es lohnt sich eine Zahnzusatzversicherung abzuschließen.',
  'Kassenlösung heißt nicht gleich optimale Lösung.',
  'Lassen Sie die Restaurationen aus hochwertigem Kunststoff oder Keramik machen.',
  'Lassen Sie sich ausführlich beraten.',
  'Ein guter Zahnersatz ist für eine gute Lebensqualität sehr wichtig.',
  'Um Mundgeruch zu vermeiden, verwenden Sie Zungenreiniger und evtl. eine Halitosis Zahnpasta.',
  'Bei Unklarheiten im Festzuschussbereich wie z.B. Härtefallanträge wenden Sie sich an Ihre Krankenkasse.',
  'Notdiensttelefonnummer bei Schmerzen am Wochenende: 0711 7877711.',
];

function AdvicesSection({ width }) {
  if (!width) {
    return null;
  }

  return (
    <div className={styles.container} style={{ width: width || 'inherit' }}>
      <h1>32 tipps</h1>

      <Image fluid src={Brush} className={styles.brush} alt="Advices brush" />

      <Slider autoplay dots={false} arrows={false} className={styles.carousel}>
        {advices.map((advice, index) => (
          <div key={index} className={styles.advice}>
            {advice}
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default memo(AdvicesSection);
