/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Image } from 'react-bootstrap';

import Brush from 'assets/brushes/services.png';

import styles from './ServicesSection.module.scss';

function ServicesSection() {
  return (
    <div className={styles.container}>
      <h1>leistungen</h1>
      <ul className="list-unstyled center-block">
        <li>
          <Image
            fluid
            src={Brush}
            className={styles.brush}
            alt="Services brush"
          />
        </li>
        <li>Prophylaxe und professionelle Zahnreinigung</li>
        <li>Kariesbehandlung</li>
        <li>
          Endodontie <span>Wurzelkanalbehandlung</span>
        </li>
        <li>
          Parodontologie <span>Zahnfleischbehandlung</span>
        </li>
        <li>
          Prothetik <span>Zahnersatz</span>
        </li>
        <li>Implantation</li>
        <li>
          Ästhetische Zahnheilkunde <span>Zahnaufhellung und Veneers</span>
        </li>
        <li>Kinderbehandlung</li>
      </ul>
    </div>
  );
}

export default memo(ServicesSection);
