/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';

import useDrawerProvider from 'common/hooks/useDrawerProvider';
import Close from 'assets/util/x.png';

import styles from './Drawer.module.scss';

function Drawer({ width }) {
  const { drawerState, dispatch } = useDrawerProvider();

  const { isOpen, Content, item } = drawerState;

  const closeDrawer = () => {
    dispatch({
      type: 'CLOSE_DRAWER',
    });
  };

  return (
    <div className={styles.container}>
      <Container fluid>
        <Row className={styles.row}>
          <CSSTransition
            in={isOpen}
            timeout={500}
            classNames={{
              enter: styles['transition-enter'],
              enterActive: styles['transition-enter-active'],
              enterDone: styles['transition-enter-done'],
              exit: styles['transition-exit'],
              exitActive: styles['transition-exit-active'],
            }}
          >
            <>
              <Content width={width} item={item}>
                <Image
                  fluid
                  src={Close}
                  className={styles.close}
                  onClick={closeDrawer}
                  alt="Close button"
                />
              </Content>
              <Col
                md={isOpen ? 5 : 0}
                className={styles.overlay}
                onClick={closeDrawer}
              />
            </>
          </CSSTransition>
        </Row>
      </Container>
    </div>
  );
}

export default memo(Drawer);
