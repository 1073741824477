/* eslint-disable react/prop-types */
import React, { forwardRef, memo, useEffect } from 'react';
import { Image } from 'react-bootstrap';

import Logo from 'assets/logo/32pro-logo-blue.png';

import styles from './LogoSection.module.scss';

function LogoSection({ element }) {
  useEffect(() => {
    const script = document.createElement('script');

    script.src =
      'https://cdn1.jameda-elements.de/widgets/siegel/81338841_1/441735/';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={styles.container}>
      <Image
        ref={element}
        fluid
        src={Logo}
        className={styles.logo}
        alt="32 pro logo"
      />
      <div id="jameda-widget-container441735" className={styles.jameda}>
        <a
          href="https://www.jameda.de/stuttgart/zahnaerzte/parodontologen/fachgebiet/?utm_content=BWnote&amp;utm_source=Kunden-Homepages&amp;utm_medium=Badges&amp;utm_term=81338841&amp;utm_campaign=Badges"
          className="jam_link_check"
          target="_blank"
          rel="noopener noreferrer"
        />
      </div>
    </div>
  );
}

export default memo(forwardRef((_, ref) => <LogoSection element={ref} />));
