/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Col, Image } from 'react-bootstrap';

import Brush from 'assets/brushes/info.png';

import styles from './Imprint.module.scss';

function Imprint({ children }) {
  return (
    <Col sm={12} className={styles.container}>
      <div className={styles.details}>
        <h1>impressum</h1>
        <Image fluid src={Brush} className={styles.brush} alt="Imprint brush" />

        <div className={styles.description}>
          <br />
          32pro
          <br />
          Zahnarzt Maksimilian Gapontsev
          <br />
          Johannesstraße 47b
          <br />
          70176 Stuttgart
          <br />
          <a href="mailto:32pro@mail.de">32pro@mail.de</a>
          <br /> tel +49 (0) 711 627 66 010
          <br />
          fax +49 (0) 711 627 66 013
          <span>Gesetzliche Berufsbezeichnung:</span>
          Zahnarzt
          <span>Verleihender Staat:</span>
          Bundesrepublik Deutschland
          <span>Zuständige Kammer:</span>
          Landeszahnärztekammer Baden-Württemberg
          <br />
          Albstadtweg 9
          <br />
          70567 Stuttgart
          <br />
          <a href="http://www.lzk-bw.de/">http://www.lzk-bw.de/</a>
          <span>Zuständige Aufsichtsbehörde:</span>
          Kassenzahnärztliche Vereinigung Baden-Württemberg
          <br />
          Albstadtweg 9
          <br />
          70567 Stuttgart
          <br />
          <a href="http://www.kzvbw.de/site//">http://www.kzvbw.de/site/</a>
          <span> Ust-ID:</span>
          DE298076215
        </div>
      </div>

      {children}
    </Col>
  );
}

export default memo(Imprint);
