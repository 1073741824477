/* eslint-disable react/prop-types */
import React, { memo, useState } from 'react';
import { Image } from 'react-bootstrap';

import RJ from 'assets/rj/logo-blue.png';

import Imprint from 'components/mobile/imprint/Imprint';
import Privacy from 'components/mobile/privacy/Privacy';
import Close from 'assets/util/x.png';

import styles from './FooterSection.module.scss';

function FooterSection({ element }) {
  const [content, setContent] = useState();

  const openImprint = () => {
    window.scrollTo(0, 0);
    setContent(
      <Imprint>
        <Image
          fluid
          src={Close}
          className={styles.close}
          onClick={() => {
            setContent();
          }}
          alt="Close button"
        />
      </Imprint>,
    );
  };

  const openPrivacy = () => {
    window.scrollTo(0, 0);
    setContent(
      <Privacy>
        <Image
          fluid
          src={Close}
          className={styles.close}
          onClick={() => {
            setContent();
          }}
          alt="Close button"
        />
      </Privacy>,
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.meta}>
        <div role="presentation" onClick={openImprint}>
          Impressum
        </div>
        <div role="presentation" onClick={openPrivacy}>
          Datenschutz
        </div>
      </div>
      <Image
        ref={element}
        fluid
        src={RJ}
        className={styles.logo}
        alt="RJ logo"
      />
      {content}
    </div>
  );
}

export default memo(FooterSection);
