/* eslint-disable react/prop-types,react/no-array-index-key */

import React, { memo } from 'react';
import { Image } from 'react-bootstrap';

import Brush from 'assets/brushes/contact.png';
import Map from 'assets/contact/map.jpg';
import Facebook from 'assets/contact/f.png';

import useDrawerProvider from 'common/hooks/useDrawerProvider';
import ContactDetails from 'components/desktop/contact-details/ContactDetails';
import styles from './ContactSection.module.scss';

function ContactSection({ width }) {
  const { dispatch } = useDrawerProvider();

  const openContact = () => {
    dispatch({
      type: 'OPEN_DRAWER',
      Content: ContactDetails,
    });
  };

  return (
    <div className={styles.container} style={{ width: width || 'inherit' }}>
      <Image fluid src={Brush} className={styles.brush} alt="Contact brush" />

      <Image
        fluid
        src={Map}
        onClick={() => {
          openContact();
        }}
        className={styles.map}
        alt="Contact map"
      />

      <ul className="list-unstyled center-block">
        <li>
          <span>P</span>
          <span>Parkplätze im Hof</span>
        </li>
        <li>
          <span>U</span>
          <span>U9 / U2</span>
        </li>
        <li>
          <span>H</span>
          <span>Schloss-/Johannesstr.</span>
        </li>
      </ul>

      <div className={styles.info}>
        <h1>kontakt</h1>
        <p>32pro</p>

        <p>Zahnarzt Maksimilian Gapontsev</p>
        <br />

        <p>Johannesstraße 47b</p>

        <p>70176 Stuttgart</p>
        <br />

        <p>
          <a href="mailto:32pro@mail.de">32pro@mail.de</a>
        </p>
        <br />

        <p>tel +49 (0) 711 627 66 010</p>

        <p>fax +49 (0) 711 627 66 013</p>

        <a
          href="https://www.facebook.com/Zahnarzt32pro"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.facebook}
        >
          <Image fluid src={Facebook} alt="Contact map" />
        </a>
      </div>
    </div>
  );
}

export default memo(ContactSection);
