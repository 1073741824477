/* eslint-disable react/prop-types,react/no-array-index-key,no-return-assign */
import React, { memo, useState, useRef, useEffect } from 'react';
import { Col, Image } from 'react-bootstrap';

import Brush from 'assets/brushes/services.png';
import Prophilaxe from 'assets/services/prophilaxe.jpg';
import Karies from 'assets/services/karies.jpg';
import Endodontie from 'assets/services/endodontie.jpg';
import Parodontologie from 'assets/services/parodontologie.jpg';
import Prothetik from 'assets/services/prothetik.jpg';
import Implantation from 'assets/services/implantation.jpg';
import Bleaching from 'assets/services/bleaching.jpg';
import Children from 'assets/services/children.jpg';

import Slider from 'react-slick';
import styles from './ServicesDetails.module.scss';

const services = [
  {
    image: Prophilaxe,
    title: <h3>Prophylaxe und professionelle Zahnreinigung</h3>,
    description: (
      <div className={styles.description}>
        Die professionelle Zahnreinigung ist ein Hauptbestandteil der
        zahnmedizinischen Prophylaxe. Eine gute Mundhygiene ist eine wichtige
        Voraussetzung für gesunde Zähne und gesundes Zahnfleisch. Bei der
        professionellen Zahnreinigung werden alle weichen und harten Beläge
        sowie alle Verfärbungen oberhalb des Zahnfleischrandes entfernt. Die
        Interdentalräume werden gründlich gereinigt und angeraute
        Zahnoberflächen poliert. Für die Gesundheit Ihrer Zähne nehmen wir
        wirklich viel Zeit und helfen Ihnen auch Zuhause ein optimales Ergebniss
        zu erzielen.
      </div>
    ),
  },
  {
    image: Karies,
    title: <h3>Kariesbehandlung</h3>,
    description: (
      <div className={styles.description}>
        Bei der Behandlung der Karies durch eine Füllungstherapie gilt es meist,
        die durch Kariesbakterien infizierte und zerstörte Zahnhartsubstanz zu
        entfernen und den Zahn mit einem meistens zahnfarbenem Füllungsmaterial
        zu rekonstruieren. In der Regel sind das Kunstofffüllungen oder auch
        hochwertige Keramikeinlagefüllungen, auch als Inlay bekannt, die durch
        Zahntechniker hergestellt und dann durch Zahnarzt in den Zahn eingeklebt
        werden.
      </div>
    ),
  },
  {
    image: Endodontie,
    title: (
      <h3>
        Endodontie
        <br />
        <span>Wurzelkanalbehandlung</span>
      </h3>
    ),
    description: (
      <div className={styles.description}>
        Endodontologie ist die Lehre von Zahnmark (Pulpa), dessen Erkrankungen,
        deren Diagnostik und Therapien. Endodontie ist die praktische Anwendung
        dieser Lehre. Sie wird als Wurzelkanalbehandlung bezeichnet. Dabei wird
        der durch Entzündung zerstörte Zahnnerv entfernt und durch eine
        gummiartige Wurzelfüllung ersetzt. Heuzutage gibt es moderne Endogeräte,
        die die ganze Wurzelbehandlung viel schneller und qualitativ besser
        gestalten.
      </div>
    ),
  },
  {
    image: Parodontologie,
    title: (
      <h3>
        Parodontologie <br />
        <span>Zahnfleischbehandlung</span>
      </h3>
    ),
    description: (
      <div className={styles.description}>
        Durch den aggressiv vermehrten Bakterien in Kombination mit
        verschiedenen Einflussfaktoren wie z.B. Stress, Diabetes und Rauchen
        kann es zu eine Zahnfleischentzündung mit Zerstörung des
        Zahnhalteapparates kommen. Je nach Schwerergrad der Erkrankung kann sie
        chirurgisch oder konservativ therapiert werden, wonach die Zähne fester
        im Knochen stehen und das Zahnfleisch nicht mehr blutet.
      </div>
    ),
  },
  {
    image: Prothetik,
    title: (
      <h3>
        Prothetik <br />
        <span>Zahnersatz</span>
      </h3>
    ),
    description: (
      <div className={styles.description}>
        Fehlende Zähne können mit einem in Labor hergestellten Zahnersatz
        ersetzt werden. Der kann sowohl herausnehmbar als auch festsitzend sein.
        Für jeden Patient wird eine individuelle Therapieplanung vorbereitet.
        Wir arbeiten mit Zahnlabors, die unmittelbar in Stuttgart sich befinden
        und qualitativ hervorragende Arbeit leisten.
      </div>
    ),
  },
  {
    image: Implantation,
    title: <h3>Implantation</h3>,
    description: (
      <div className={styles.description}>
        Imlantate sind die in den Knochen eingedrehten Schrauben in der Regel
        aus Titan. Sie zeichnen sich durch gute Biokompatibilität, Stabilität
        und Tragekomfort und können mit entsprechender Pflege lebenslang halten.
      </div>
    ),
  },
  {
    image: Bleaching,
    title: (
      <h3>
        Ästhetische Zahnheilkunde <br />
        <span>Zahnaufhellung und Veneers</span>
      </h3>
    ),
    description: (
      <div className={styles.description}>
        Die Farbe der Zähne ist erblich festgestellt. Im Laufe der Jahre sammeln
        sich Farbstoffe aus Nahrungsmitteln wie Kaffee, Tee, Cola, Rotwein im
        Zahnschmelz an und färben diesen dunkler. Durch reines Zähneputzen ist
        dies nicht vermeidbar. Nur Bleaching verhilft uns zu einem „strahlen
        weißen Lächeln“. Das bleichen der Zähne ist eine reine kosmetische
        Leistung und wird daher nicht von den Krankenkassen bezahlt.
        <br />
        Veneers sind Keramikschälchen, die auf die Zähne festgeklebt werden und
        dementsprechend die Form und Farbe der Zähne verbessern können, sodass
        ein "Hollywoodsmile" entsteht.
      </div>
    ),
  },
  {
    image: Children,
    title: <h3>Kinderbehandlung</h3>,
    description: (
      <div className={styles.description}>
        Im Rahmen der regelmäßigen halbjährlichen Kontrolluntersuchungen werden
        bei Kindern ab 6 Jahren zusätzliche Behandlungen zur Vorbeugung und
        Gesunderhaltung der Zähne durchgeführt. Dazu gehören:
        <ul className="list center-block vh_margin-top30 vh_padding-left160">
          <li className="vh_margin-bottom15 vh_line-height22">
            Aufklärung über die richtige Mundhygiene
          </li>
          <li className="vh_margin-bottom15 vh_line-height22">
            Demonstration und Üben des richtigen Zähneputzens, altersgerecht
            umgesetzt und an die motorischen Fähigkeiten des Kindes angepasst
          </li>
          <li className="vh_margin-bottom15 vh_line-height22">
            Ernährungsberatung
          </li>
          <li className="vh_margin-bottom15 vh_line-height22">
            Gründliche Reinigung und Politur nach Überprüfung der Zahnpflege mit
            Färbelösung
          </li>
          <li className="vh_margin-bottom15 vh_line-height22">
            Karieskontrolle und ggf. Röntgenaufnahmen der Zahnzwischenräume
          </li>
          <li className="vh_margin-bottom15 vh_line-height22">
            Fluoridierung der Zähne
          </li>
          <li className="vh_margin-bottom15 vh_line-height22">
            Fissurenversiegelung auf den Kauflächen
          </li>
        </ul>
      </div>
    ),
  },
];

function ServicesDetails({ width, item, children }) {
  const [sliders, setSliders] = useState({
    mainSlider: null,
    descriptionSlider: null,
  });

  const mainSliderRef = useRef();
  const descriptionSliderRef = useRef();

  useEffect(() => {
    setSliders({
      mainSlider: mainSliderRef.current,
      descriptionSlider: descriptionSliderRef.current,
    });
  }, [mainSliderRef, descriptionSliderRef]);

  const { mainSlider, descriptionSlider } = sliders;

  const nextSlide = () => {
    mainSlider.slickNext();
    descriptionSlider.slickNext();
  };

  return (
    <Col md={7} className={styles.container}>
      <div className={styles.details} style={{ width: width || 'inherit' }}>
        <h1>leistungen</h1>
        <Image
          fluid
          src={Brush}
          className={styles.brush}
          alt="Services details brush"
        />
        <Slider
          dots
          fade
          infinite
          autoplay={false}
          draggable={false}
          arrows={false}
          ref={s => {
            mainSliderRef.current = s;
          }}
          initialSlide={item}
          className={styles.carousel}
        >
          {services.map((service, index) => (
            <div
              role="presentation"
              key={index}
              onClick={nextSlide}
              className={styles.service}
            >
              <div>
                <Image
                  fluid
                  src={service.image}
                  className={styles.brush}
                  alt="Service icon"
                />
                {service.title}
              </div>
            </div>
          ))}
        </Slider>

        <Slider
          fade
          infinite
          dots={false}
          autoplay={false}
          draggable={false}
          arrows={false}
          initialSlide={item}
          ref={s => {
            descriptionSliderRef.current = s;
          }}
          className={styles['description-carousel']}
        >
          {services.map((service, index) => (
            <div role="presentation" key={index} className={styles.service}>
              <div>{service.description}</div>
            </div>
          ))}
        </Slider>
      </div>

      {children}
    </Col>
  );
}

export default memo(ServicesDetails);
