import React, { memo } from 'react';
import { Image } from 'react-bootstrap';

import Brush from 'assets/brushes/contact.png';
import Map from 'assets/contact/map.jpg';
import Facebook from 'assets/contact/f.png';

import styles from './ContactSection.module.scss';

function ContactSection() {
  return (
    <div className={styles.container}>
      <h1>kontakt</h1>

      <a
        href="https://www.facebook.com/Zahnarzt32pro"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.facebook}
      >
        <Image fluid src={Facebook} alt="Facebook" />
      </a>

      <div className={styles.info}>
        <p>32pro</p>

        <p>Zahnarzt Maksimilian Gapontsev</p>
        <br />

        <p>Johannesstraße 47b</p>

        <p>70176 Stuttgart</p>
        <br />

        <p>
          <a href="mailto:32pro@mail.de">32pro@mail.de</a>
        </p>
        <br />

        <p>tel +49 (0) 711 627 66 010</p>

        <p>fax +49 (0) 711 627 66 013</p>
      </div>

      <div className={styles.directions}>
        <div>
          <Image
            fluid
            src={Brush}
            className={styles.brush}
            alt="Contact brush"
          />
          <Image
            fluid
            src={Map}
            className={styles.map}
            alt="Contact map"
            onClick={() => {
              window.open(
                'https://www.google.de/maps/place/Johannesstra%C3%9Fe+47B,+70176+Stuttgart/@48.77831,9.1606,17z/data=!4m7!1m4!3m3!1s0x4799db3f7f78785b:0x1e16bfd1e8bc58b7!2sJohannesstra%C3%9Fe+47B,+70176+Stuttgart!3b1!3m1!1s0x4799db3f7f78785b:0x1e16bfd1e8bc58b7?hl=de',
              );
            }}
          />
        </div>

        <ul className="list-unstyled center-block">
          <li>
            <span>P</span>
            <span>Parkplätze im Hof</span>
          </li>
          <li>
            <span>U</span>
            <span>U9 / U2</span>
          </li>
          <li>
            <span>H</span>
            <span>Schloss-/Johannesstr.</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default memo(ContactSection);
