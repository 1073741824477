/* eslint-disable react/prop-types */
import React, { forwardRef, memo } from 'react';
import { Image } from 'react-bootstrap';

import Anna from 'assets/team/anna.jpg';
import Helmina from 'assets/team/helmina.jpg';
import Max from 'assets/team/max.jpg';
import Sonja from 'assets/team/sonja.png';
import Nina from 'assets/team/nina.jpg';
import PlusIcon from 'assets/util/plus.png';

import useDrawerProvider from 'common/hooks/useDrawerProvider';
import TeamDetails from 'components/desktop/team-details/TeamDetails';
import styles from './TeamSection.module.scss';

function TeamSection({ width, element }) {
  const { dispatch } = useDrawerProvider();

  const openTeam = slide => {
    dispatch({
      type: 'OPEN_DRAWER',
      Content: TeamDetails,
      item: slide,
    });
  };

  return (
    <div className={styles.container}>
      <h1 style={{ width: width || 'inherit' }}>unser team</h1>

      <div ref={element}>
        <Image
          fluid
          className={styles.avatar}
          src={Anna}
          onClick={() => {
            openTeam(0);
          }}
          alt="Anna"
        />
        <Image fluid className={styles.plus} src={PlusIcon} alt="Plus" />
        <Image
          fluid
          className={styles.avatar}
          src={Helmina}
          onClick={() => {
            openTeam(1);
          }}
          alt="Anna"
        />
        <Image fluid className={styles.plus} src={PlusIcon} alt="Plus" />
        <Image
          fluid
          className={styles.avatar}
          src={Max}
          onClick={() => {
            openTeam(2);
          }}
          alt="Anna"
        />
        <Image fluid className={styles.plus} src={PlusIcon} alt="Plus" />
        <Image
          fluid
          className={styles.avatar}
          src={Sonja}
          onClick={() => {
            openTeam(3);
          }}
          alt="Anna"
        />
        <Image fluid className={styles.plus} src={PlusIcon} />
        <Image
          fluid
          className={styles.avatar}
          src={Nina}
          onClick={() => {
            openTeam(4);
          }}
          alt="Anna"
        />
      </div>
    </div>
  );
}

export default memo(
  forwardRef(({ width }, ref) => <TeamSection width={width} element={ref} />),
);
