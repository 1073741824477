import React, { memo } from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import LogoSection from 'components/mobile/logo-section/LogoSection';
import HoursSection from 'components/mobile/hours-section/HoursSection';
import AppointmentSection from 'components/mobile/appointment-section/AppointmentSection';
import StatusSection from 'components/mobile/status-section/StatusSection';
import ServicesSection from 'components/mobile/services-section/ServicesSection';

import TeamSection from 'components/mobile/team-section/TeamSection';
import AdvicesSection from 'components/mobile/advices-section/AdvicesSection';
import ContactSection from 'components/mobile/contact-section/ContactSection';
import JamedaSection from 'components/mobile/jameda-section/JamedaSection';
import FooterSection from 'components/mobile/footer-section/FooterSection';
import styles from './Mobile.module.scss';

function Mobile() {
  return (
    <>
      <Container fluid className={styles.container}>
        <Row>
          <Col sm={12} className={styles.logo}>
            <LogoSection />
          </Col>
        </Row>
        <Row>
          <Col sm={12} className={styles.jameda}>
            <JamedaSection />
          </Col>
        </Row>

        <Row>
          <Col sm={12} className={styles.appointment}>
            <AppointmentSection />
          </Col>
        </Row>
        <Row>
          <Col sm={12} className={styles.status}>
            <StatusSection />
          </Col>
        </Row>
        <Row>
          <Col sm={12} className={styles.hours}>
            <HoursSection />
          </Col>
        </Row>
        <Row>
          <Col sm={12} className={styles.services}>
            <ServicesSection />
          </Col>
        </Row>
        <Row>
          <Col sm={12} className={styles.team}>
            <TeamSection />
          </Col>
        </Row>
        <Row>
          <Col sm={12} className={styles.advices}>
            <AdvicesSection />
          </Col>
        </Row>
        <Row>
          <Col sm={12} className={styles.contact}>
            <ContactSection />
          </Col>
        </Row>
        <Row>
          <Col sm={12} className={styles.footer}>
            <FooterSection />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default memo(Mobile);
