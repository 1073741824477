/* eslint-disable react/prop-types */
import React, { useReducer, createContext } from 'react';

export const DrawerContext = createContext();

const initialState = {
  isOpen: false,
  Content: () => null,
  item: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_DRAWER':
      return {
        isOpen: true,
        Content: action.Content,
        item: action.item,
      };
    case 'CLOSE_DRAWER':
      return initialState;
    default:
      return state;
  }
};

export const DrawerProvider = ({ children }) => {
  const [drawerState, dispatch] = useReducer(reducer, initialState);

  return (
    <DrawerContext.Provider value={{ drawerState, dispatch }}>
      {children}
    </DrawerContext.Provider>
  );
};
