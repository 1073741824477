import React, { memo } from 'react';
import Image from 'react-bootstrap/Image';

import logo from 'assets/logo/32pro-logo-white.png';

import styles from './Landing.module.scss';

function Landing() {
  return (
    <div className={styles.container}>
      <div className={styles.overlay} />
      <Image fluid src={logo} className={styles.logo} alt="32 pro logo" />
    </div>
  );
}

export default memo(Landing);
