import { useLayoutEffect, useState, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export default function () {
  const element = useRef();
  const [width, changeWidth] = useState(0);
  const [height, changeHeight] = useState(0);

  useLayoutEffect(() => {
    const el = element.current;

    if (el && el instanceof Element) {
      const resizeObserver = new ResizeObserver(entries => {
        if (!Array.isArray(entries)) {
          return;
        }

        // Since we only observe the one element, we don't need to loop over the
        // array
        if (!entries.length) {
          return;
        }

        const entry = entries[0];
        changeWidth(entry.contentRect.width);
        changeHeight(entry.contentRect.height);
      });

      resizeObserver.observe(el);

      return () => resizeObserver.unobserve(el);
    }

    return undefined;
  }, []);

  return [element, width, height];
}
