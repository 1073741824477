/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Image } from 'react-bootstrap';

import Anna from 'assets/team/anna.jpg';
import Helmina from 'assets/team/helmina.jpg';
import Max from 'assets/team/max.jpg';
import Sonja from 'assets/team/sonja.png';
import Nina from 'assets/team/nina.jpg';
import Slider from 'react-slick';
import styles from './TeamSection.module.scss';

const team = [
  {
    image: Anna,
    description: (
      <div className={styles.description}>
        <h2>Anna Efremova</h2>
        <h3>Auszubildende</h3>
      </div>
    ),
  },
  {
    image: Helmina,
    description: (
      <div className={styles.description}>
        <h2>Helmina Zulovic</h2>
        <h3>Auszubildende</h3>
      </div>
    ),
  },
  {
    image: Max,
    description: (
      <div className={styles.description}>
        <h2>Maksimilian Gapontsev</h2>
        <h3>Zahnarzt - Praxisinhaber</h3>
      </div>
    ),
  },
  {
    image: Sonja,
    description: (
      <div className={styles.description}>
        <h2>Sofia Gapontseva</h2>
        <h3>Zahntechnikerin - Leiterin des Praxislabors</h3>
      </div>
    ),
  },
  {
    image: Nina,
    description: (
      <div className={styles.description}>
        <h2>Nina Wotinzew</h2>
        <h3>Zahnmedizinische Fachangestellte</h3>
      </div>
    ),
  },
];

function TeamSection() {
  return (
    <div className={styles.container}>
      <h1>unser team</h1>

      <Slider
        dots
        infinite
        autoplay={false}
        draggable={false}
        arrows={false}
        className={styles.carousel}
      >
        {team.map(member => (
          <div role="presentation" className={styles.member}>
            <div>
              <Image
                fluid
                src={member.image}
                className={styles.brush}
                alt="Member photo"
              />
              {member.description}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default memo(TeamSection);
