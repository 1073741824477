import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { DrawerProvider } from 'common/context/DrawerContext';
import useTimeout from 'common/hooks/useTimeout';
import Landing from 'components/landing/Landing';
import Desktop from 'components/desktop/Desktop';
import Mobile from 'components/mobile/Mobile';

import './App.css';

function App() {
  const [showLanding, setShowLanding] = useState(true);

  useTimeout(() => setShowLanding(false), 1000);

  const isDesktop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  });

  let content = <Landing />;

  if (!showLanding) {
    if (isDesktop) {
      content = <Desktop />;
    } else {
      content = <Mobile />;
    }
  }
  return (
    <DrawerProvider>
      <div>{content}</div>
    </DrawerProvider>
  );
}

export default App;
