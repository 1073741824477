/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Col, Image } from 'react-bootstrap';

import Brush from 'assets/brushes/info.png';

import styles from './Privacy.module.scss';

function Privacy({ children }) {
  const disableGoogleAnalytics = e => {
    e.preventDefault();
    window.disableGoogleAnalytics();
    alert('Google Analytics wurde deaktiviert');
  };

  return (
    <Col sm={12} className={styles.container}>
      <div className={styles.details}>
        <h1>datenschutz</h1>
        <Image fluid src={Brush} className={styles.brush} alt="Imprint brush" />

        <div className={styles.description}>
          <h2>Datenschutzerklärung</h2>
          <section>
            Sehr geehrte Damen und Herren, sehr geehrte Patientinnen und
            Patienten, der Schutz Ihrer personenbezogenen Daten ist uns wichtig.
            Nach der EU-Datenschutzgrundverordnung (DSGVO),
            Bundesdatenschutzgesetz (BDSG) und Telemediengesetz (TMG) sind wir
            verpflichtet, Sie darüber zu informieren, zu welchem Zweck auf
            unserer Homepage personenbezogene Daten erhoben und verwendet
            werden, auf welche Art dies geschieht und welchen Umfang dies hat.
            Dieser Information können Sie auch entnehmen, welche Rechte Sie
            hinsichtlich des Datenschutzes haben. Diese Datenschutzerklärung
            bezieht sich auf unser Internetangebot. Sie bezieht sich
            ausdrücklich nicht auf das Behandlungsverhältnis. Hier werden Sie
            bei Besuch der Praxis gesondert informiert.
          </section>
          <section>
            <h3>1. Verantwortlich für die Datenverarbeitung ist:</h3>
            Für Datenerhebungen unter http://www.32pro.de/ ist verantwortlich:{' '}
            <br />
            32pro <br />
            Zahnarzt Maksimilian Gapontsev <br />
            Johannesstraße 47b <br />
            70176 Stuttgart <br />
            32pro@mail.de <br />
            tel +49 (0) 711 627 66 010 <br />
            fax +49 (0) 711 627 66 013
          </section>
          <section>
            <h3>2. Verarbeitungszwecke:</h3>
            Wir erheben, verarbeiten und nutzen Ihre personenbezogenen Daten
            nur, wenn und soweit Sie hierzu eingewilligt haben oder dies durch
            Gesetz erlaubt ist. Die Erhebung personenbezogener Daten und ihre
            Verarbeitung durch die Zahnarztpraxis 32pro verfolgt nachfolgende
            Zwecke: <br />
            <ul>
              <li>
                Betrieb und Optimierung des Internetauftritts über Analyse
                Informationsvermittlung betreffend das Leistungsangebot der
                Zahnarztpraxis 32pro und Mitarbeiter{' '}
              </li>
              <li>
                Abwicklung des Terminwesens und der Patientenkommunikation
                Erfüllung gesetzlicher Informations-, Mitteilungs-, Auskunfts-,
                Aufbewahrungspflichten Kommunikation mit Besuchern und
                Interessenten der Webseite http://www.32pro.de/{' '}
              </li>
              <li>
                Abwehr von Zahlungs-, Erfüllungs-, Anfechtungs-, Unterlassungs-,
                und/oder Schadensersatzansprüchen Dritter, insbesondere Nutzer
                des Webangebotes, Kunden und sonstige Dritte.
              </li>
            </ul>
          </section>
          <section>
            <h3>3. Empfänger Ihrer Daten</h3>
            Die Seite http://www.32pro.de/ ist besuchbar, ohne Angaben zu Ihrer
            Person zu machen. Gleichwohl werden schon bei dem einfachen
            Seitenbesuch Informationen zum Zugriff:
            <ul>
              <li>Browsertyp/-version</li>
              <li>Verwendetes Betriebssystem</li>
              <li>Referrer URL (die zuvor besuchte Seite)</li>
              <li>Hostname des zugreifenden Rechners (IP-Adresse)</li>
              <li>Übertragene Datenmenge</li>
              <li>Datum und Uhrzeit der Serveranfrage gespeichert.</li>
            </ul>
            Diese Daten sind für uns nicht bestimmten Personen zuordenbar. Eine
            Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
            vorgenommen. Die Daten werden gelöscht, sobald sie für den Zweck der
            Erhebung nicht mehr notwendig sind. Grundlage für die
            Datenverarbeitung ist Art. 6 Abs. 1 lit. f) DSGVO auf Basis unseres
            berechtigten Interesses an der Verbesserung der Stabilität,
            Sicherheit und Funktionalität unserer Website.
          </section>
          <section>
            <h3>4.Information zur Datenherkunft: </h3>
            Personenbezogene Daten werden beim Betroffenen (Nutzer der
            Internetseite) erhoben.
          </section>
          <section>
            <h3>5. Informationen zur Datenverarbeitung</h3>
            <h4>Einsatz von Cookies:</h4>
            Ein Cookie ist eine kleine Datei, die auf dem Computer eines
            Besuchers gespeichert wird, sobald er eine Internetseite aufruft.
            Wenn er diese Internetseite erneut aufruft, zeigt der Cookie an,
            dass es sich um einen wiederholten Besuch handelt. Wir übermitteln
            Cookies mittels Ihres Webbrowsers auf die Festplatte Ihres Computers
            und können diese während Ihres laufenden Besuchs auf unseren
            Internetseiten und bei späteren Besuchen innerhalb eines Jahres
            auslesen. Unsere Cookies sind mit Hilfe der Sicherheitsstandards
            Ihres Browsers gegen ein Auslesen durch Dritte geschützt. Cookies
            dienen im Wesentlichen dem Zwecks, Ihnen die Nutzung unseres
            Angebots so komfortabel wie möglich zu machen. Sollten sie dennoch
            die Cookies deaktivieren wollen, können Sie über die Hilfe-Funktion
            in der Menüleiste des von Ihnen genutzten Webbrowsers nachlesen,
            welche Einstellungen Sie vornehmen müssen, um die Speicherung neuer
            Cookies zu verhindern. Dort erhalten Sie außerdem Informationen über
            weitere Cookie- Einstellungen, bspw. Hinweis- und Warnfunktionen zu
            der Löschung existierender Cookies. Um eine uneingeschränkte und
            störungsfreies Nutzung unserer Internetseiten zu gewährleisten,
            empfehlen wir Ihnen, die Cookie-Funktionen vollständig eingeschaltet
            zu lassen. Falls Sie nicht möchten, dass Cookies zur
            Reichweitenmessung auf Ihrem Endgerät gespeichert werden, können Sie
            dem Einsatz dieser Dateien u.a. auch über nachfolgende Webseiten
            widersprechen: <br />
            <a
              href="http://optout.networkadvertising.org/?c=1#!/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://optout.networkadvertising.org/?c=1#!/
            </a>
            <br />
            <a
              href="http://optout.aboutads.info/?c=2#!/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://optout.aboutads.info/?c=2#!/
            </a>
            <br />
            <h4>Online-Termin</h4>
            Daten, die Sie bei uns im Online-Formular eingeben, werden
            ausschließlich auf unseren Servern in der Praxis verarbeitet und
            gespeichert. Sie werden in keinem Fall an Dritte weitergegeben.
            <br />
            <h4>E-Mail</h4>
            Wir benutzen kein Kontaktformular. Die Datenverarbeitung über unsere
            Mailadressen erfolgt auf der Grundlage von Art. 6 Abs. 1 Satz 1 lit.
            f DSGVO. Das berechtigte Interesse an der Verarbeitung der Daten
            liegt darin, Anfragen beantworten zu können. Zielt der Kontakt mit
            Ihnen auf den Abschluss einer Beauftragung (zahnärztliche
            Behandlung) ab, so kommt hier zusätzlich die Rechtsgrundlage für die
            Verarbeitung zum Tragen: Art. 6 Abs. 1 lit. b DSGVO.
            <br />
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich.
            <br />
            <h4>Google Analytics</h4>
            Diese Website nutzt Funktionen des Webanalysedienstes Google
            Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway
            Mountain View, CA 94043, USA. Die Benutzung erfolgt gemäß Art. 6
            Abs. 1 lit. f auf Grundlage unseres berechtigten Interesses an der
            statistischen Analyse des Nutzerverhaltens und zu Optimierungs- und
            Marketingzwecken. Google Analytics verwendet so genannte “Cookies”.
            Das sind Textdateien, die auf Ihrem Computer gespeichert werden und
            die eine Analyse der Benutzung der Website durch Sie ermöglichen.
            Die durch den Cookie erzeugten Informationen über Ihre Benutzung
            dieser Website werden in der Regel an einen Server von Google in den
            USA übertragen und dort gespeichert.
            <br />
            <h4>IP-Anonymisierung</h4>
            Wir haben auf dieser Website die Funktion IP-Anonymisierung
            aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
            Mitgliedstaaten der Europäischen Union oder in anderen
            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
            vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird
            die volle IP-Adresse an einen Server von Google in den USA
            übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
            Website wird Google diese Informationen benutzen, um Ihre Nutzung
            der Website auszuwerten, um Reports über die Websiteaktivitäten
            zusammenzustellen und um weitere mit der Websitenutzung und der
            Internetnutzung verbundene Dienstleistungen gegenüber dem
            Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics
            von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen
            Daten von Google zusammengeführt.
            <br />
            <h4>Browser Plugin</h4>
            Sie können die Speicherung der Cookies durch eine entsprechende
            Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
            darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website vollumfänglich werden nutzen können. Sie
            können darüber hinaus die Erfassung der durch den Cookie erzeugten
            und auf Ihre Nutzung der Website bezogenen Daten an Google sowie die
            Verarbeitung dieser Daten durch Google verhindern, indem Sie das
            unter dem folgenden Link verfügbare Browser-Plugin herunterladen und
            installieren:
            <a
              href="https://tools.google.com/dlpage/gaoptout?hl=de"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://tools.google.com/dlpage/gaoptout?hl=de
            </a>
            <br />
            <h4>Widerspruch gegen Datenerfassung</h4>
            Sie können die Erfassung Ihrer Daten durch Google Analytics
            verhindern, indem Sie auf folgenden Link klicken. Es wird ein
            Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei
            zukünftigen Besuchen dieser Website verhindert:
            <br />
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href=""
              target="_blank"
              rel="noopener noreferrer"
              onClick={disableGoogleAnalytics}
            >
              Erfassung von Daten durch Google Analytics für diese Website
              deaktivieren
            </a>
            Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
            finden Sie in der Datenschutzerklärung von Google:
            <br />
            <a
              href="https://support.google.com/analytics/answer/6004245?hl=de"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://support.google.com/analytics/answer/6004245?hl=de
            </a>
            <br />
            <h4>Auftragsdatenverarbeitung</h4>
            Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung
            abgeschlossen und setzen die strengen Vorgaben der deutschen
            Datenschutzbehörden bei der Nutzung von Google Analytics vollständig
            um.
            <br />
            <h4>
              Einsatz von Google Ads Google Conversion-Tracking (Werbeanzeige):
            </h4>
            Diese Website verwendet Google AdWords. AdWords ist ein
            Online-Werbeprogramm der Google Inc., 1600 Amphitheatre Parkway,
            Mountain View, CA 94043, United States (“Google”).
            <br />
            Im Rahmen von Google AdWords nutzen wir das so genannte
            Conversion-Tracking. Wenn Sie auf eine von Google geschaltete
            Anzeige klicken wird ein Cookie für das Conversion-Tracking gesetzt.
            Bei Cookies handelt es sich um kleine Textdateien, die der
            Internet-Browser auf dem Computer des Nutzers ablegt. Diese Cookies
            verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der
            persönlichen Identifizierung der Nutzer. Besucht der Nutzer
            bestimmte Seiten dieser Website und das Cookie ist noch nicht
            abgelaufen, können Google und wir erkennen, dass der Nutzer auf die
            Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.
            <br />
            Jeder Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies
            können nicht über die Websites von AdWords-Kunden nachverfolgt
            werden. Die mithilfe des Conversion-Cookies eingeholten
            Informationen dienen dazu, Conversion-Statistiken für AdWords-Kunden
            zu erstellen, die sich für Conversion-Tracking entschieden haben.
            Die Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre
            Anzeige geklickt haben und zu einer mit einem
            Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie
            erhalten jedoch keine Informationen, mit denen sich Nutzer
            persönlich identifizieren lassen. Wenn Sie nicht am Tracking
            teilnehmen möchten, können Sie dieser Nutzung widersprechen, indem
            Sie das Cookie des Google Conversion-Trackings über ihren
            Internet-Browser unter Nutzereinstellungen leicht deaktivieren. Sie
            werden sodann nicht in die Conversion-Tracking Statistiken
            aufgenommen.
            <br />
            Die Speicherung von “Conversion-Cookies” erfolgt auf Grundlage von
            Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
            berechtigtes Interesse an der Analyse des Nutzerverhaltens, um
            sowohl sein Webangebot als auch seine Werbung zu optimieren.
            <br />
            Mehr Informationen zu Google AdWords und Google Conversion-Tracking
            finden Sie in den Datenschutzbestimmungen von Google:
            https://www.google.de/policies/privacy/.
            <br />
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und Cookies nur im Einzelfall erlauben,
            die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.
            <br />
            <h4>Einsatz von Google Maps</h4>
            Wir setzen auf unserer Seite die Komponente "Google Maps" der Firma
            Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA,
            nachfolgend "Google", ein.
            <br />
            Bei jedem einzelnen Aufruf der Komponente "Google Maps" wird von
            Google ein Cookie gesetzt, um bei der Anzeige der Seite, auf der die
            Komponente "Google Maps" integriert ist, Nutzereinstellungen und
            -daten zu verarbeiten. Dieses Cookie wird im Regelfall nicht durch
            das Schließen des Browsers gelöscht, sondern läuft nach einer
            bestimmten Zeit ab, soweit es nicht von Ihnen zuvor manuell gelöscht
            wird.
            <br />
            Wenn Sie mit dieser Verarbeitung Ihrer Daten nicht einverstanden
            sind, so besteht die Möglichkeit, den Service von "Google Maps" zu
            deaktivieren und auf diesem Weg die Übertragung von Daten an Google
            zu verhindern. Dazu müssen Sie die Java-Script-Funktion in Ihrem
            Browser deaktivieren. Wir weisen Sie jedoch darauf hin, dass Sie in
            diesem Fall die "Google Maps" nicht oder nur eingeschränkt nutzen
            können.
            <br />
            Die Nutzung von "Google Maps" und der über "Google Maps" erlangten
            Informationen erfolgt gemäß den Google-Nutzungsbedingungen
            http://www.google.de/intl/de/policies/terms/regional.html sowie der
            zusätzlichen Geschäftsbedingungen für "Google Maps"
            <br />
            <a
              href="https://www.google.com/intl/de_de/help/terms_maps.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.google.com/intl/de_de/help/terms_maps.html
            </a>
            <br />
            <h4>Hotjar</h4>
            Diese Website benutzt Hotjar, eine Analysesoftware der Hotjar Ltd.
            („Hotjar“) (http://www.hotjar.com, 3 Lyons Range, 20 Bisazza Street,
            Sliema SLM 1640, Malta, Europe). Mit Hotjar ist es möglich das
            Nutzungsverhalten (z.B. Klicks, Mausbewegungen, Scrollhöhen) auf
            unserer Website zu messen und auszuwerten. Wir nutzen Hotjar auf
            Basis unserer berechtigten Interessen zur Optimierungs- und
            Marketingzwecken und der interessengerechten Ausgestaltung unserer
            Website gemäß Art. 6 Abs. 1 lit. f DSGVO. Nur zu diesem Zweck werden
            Daten der Nutzer unserer Website gespeichert und ausgewertet. Wir
            setzen Hotjar zur Analyse unseres Onlineangebotes und nicht der
            einzelnen Nutzer ein. Die Daten der Nutzer werden daher
            pseudonymisiert und innerhalb der Europäischen Union sowie auf
            Grundlage des Rahmens von Hotjar angebotenen
            Auftragsverarbeitungsvertrages verarbeitet. Eingaben der Nutzer,
            z.B. in Formularen oder Tastenandrücke, werden nicht verarbeitet,
            d.h. weder von Hotjar gespeichert noch an Hotjar übermittelt (es sei
            denn diese Eingaben sind für Nutzer erkennbar zu Zwecken der
            Auswertung bestimmt, wie z.B. bei Feedback-Formularen).
            <br />
            Zu den vorgenannten Zwecken speichert Hotjar auf Geräten der Nutzer
            Cookies mit einer pseudonymen Identifizierungsnummer und wertet
            diese aus. Die Cookies, die Hotjar nutzt, haben eine
            unterschiedliche „Lebensdauer“; manche bleiben bis zu 365 Tagen,
            manche bleiben nur während des aktuellen Besuchs gültig:
            <br />
            <a
              href="https://www.hotjar.com/legal/policies/cookie-information"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.hotjar.com/legal/policies/cookie-information
            </a>
            <br />
            Die folgenden Informationen können durch Ihr Gerät und Ihren Browser
            aufgezeichnet werden:
            <ul>
              <li>
                IP-Adresse Ihres Gerätes (gesammelt und abgespeichert in einem
                anonymisierten Format)
              </li>
              <li>Bildschirmauflösung Ihres Gerätes</li>
              <li>Gerätetyp und Browserinformation</li>
              <li>Geographischer Standpunkt (nur das Land)</li>
              <li>bevorzugte Sprache, um unsere Website darzustellen</li>
              <li>Log Daten</li>
            </ul>
            <br />
            Sie können die Erfassung der Daten durch Hotjar verhindern, indem
            Sie die Do-Not-Track-Einstellungen Ihres Browers nutzen oder auf
            folgenden Link klicken und die dortigen Instruktionen befolgen:
            <br />
            <a
              href="https://www.hotjar.com/opt-out"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.hotjar.com/opt-out
            </a>
            <br />
            <h4>Einsatz von Jameda Plug-In</h4>
            Unsere Webseite nutzt Plug-Ins der Jameda GmbH, St.-Kajetan-Straße
            41, 81669 München. Wenn Sie eine unserer mit einem Jameda- Plug-Ins
            ausgestatteten Seiten besuchen, wird eine Verbindung zu den Servern
            von Jameda hergestellt. Dabei wird dem Jameda-Server mitgeteilt,
            welche unserer Seiten Sie besucht haben.
            <br />
            Jameda erhebt Informationen über die Nutzung von www.jameda.de von
            einzelnen Rechnern aus. Mit dem Sammeln solcher Informationen
            beabsichtigt Jameda sein Internetangebot zu individualisieren. Wenn
            Sie mit den Plug-In interagieren, zum Beispiel den
            „Empfehlen“-Button betätigen oder einen Kommentar abgeben, wird die
            entsprechende Information von Ihrem Browser direkt an Jameda
            übermittelt und dort gespeichert.
            <br />
            Den Zweck und Umfang der jeweiligen Datenerhebung und Informationen
            zur weiteren Verarbeitung und Nutzung der Daten durch die Anbieter
            sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum
            Schutz Ihrer Privatsphäre erfahren Sie auf den Seiten von Jameda in
            der Datenschutzerklärung
            unter:https://www.jameda.de/jameda/datenschutz.php.
            <br />
            <h4>Einsatz von Soziale Medien</h4>
            Zahnarztpraxis 32pro unterhält Auftritte in den sozialen Medien, um
            die dort aktiven Nutzer über die Leistungen und Angebote von
            Zahnarztpraxis 32pro zu informieren und bei Interesse direkt über
            die Plattformen zu kommunizieren. Aktuell sind wir in folgenden
            Netzwerken mit eigenen Online-Profilen vertreten:
            <br />
            <a
              href="https://www.facebook.com/Zahnarzt32pro"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook: https://www.facebook.com/Zahnarzt32pro
            </a>
            <br />
            <a
              href="https://instagram.com/zahnarztpraxis_32pro"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram: https://instagram.com/zahnarztpraxis_32pro
            </a>
            <br />
            <h4>Facebook</h4>
            Alle Funktionen im Social Media Netzwerk werden von Facebook, 1601
            South California Avenue, Palo Alto,CA 94304, USA angeboten. Wir
            weisen darauf hin, dass wir keinen Einfluss auf den Inhalt, Umfang
            der Nutzung, der von Facebook erhobenen Daten haben. Für weitere
            Informationen diesbezüglich verweisen wir auf die
            Datenschutzerklärung von Facebook:
            <br />
            <a
              href="https://facebook.com/privacy/explanation"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://facebook.com/privacy/explanation
            </a>
            sowie die Opt-Out-Möglichkeiten/Werbepräferenzen:
            <br />
            <a
              href="https://www.facebook.com/settings?tab=ads"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.facebook.com/settings?tab=ads
            </a>
            .
            <br />
            Über Facebook-Insights erhalten wir statistische Daten
            unterschiedlicher Kategorien, wie z.B. die Anzahl von
            Seitenaufrufen, „Gefällt mir“-Angaben, Seitenaktivitäten,
            Beitragsinteraktionen, Videoansichten, Beitragsreichweite,
            Kommentare, geteilte Inhalten. Wir verarbeiten die Daten auf Basis
            von Artikel 6 Abs. 1 lit. f, um die Seite attraktiver zu machen.
            Weitere Informationen zu Seiten-Insights-Daten finden Sie unter:
            <br />
            <a
              href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.facebook.com/legal/terms/information_about_page_insights_data
            </a>
            .
            <br />
            Als Seitenbetreiber besteht eine gemeinsame Verantwortlichkeit nach
            Artikel 26 DSGVO zwischen Facebook und Zahnarztpraxis 32pro, siehe
            hierzu das Page Controller Addendum unter <br />
            <a
              href="https://www.facebook.com/legal/terms/page_controller_addendum"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.facebook.com/legal/terms/page_controller_addendum
            </a>
            .
            <br />
            Betroffenenrechte nach der DSGVO können sowohl gegenüber Facebook
            als auch Zahnarztpraxis 32pro geltend gemacht werden.
            <br />
            <h4>Facebook Pixel</h4>
            Diese Webseite verwendet den Facebook Pixel der sozialen Netzwerke
            „Facebook“ (Facebook Inc., 1601 S. California Ave, Palo Alto,
            California 94304, USA). Dies dient dem Zweck, Besuchern unserer
            Webseite im Rahmen ihres Besuchs des sozialen Netzwerkes Facebook
            interessenbezogene Werbeanzeigen zu präsentieren. Über das Facebook
            Pixel wird beim Besuch unserer Webseite eine direkte Verbindung zu
            den Facebook-Servern hergestellt. Dabei wird an den Facebook-Server
            übermittelt, dass Sie unsere Website besucht haben und Facebook
            ordnet diese Information Ihrem persönlichen Facebook-Benutzerkonto
            zu. Wir weisen darauf hin, dass wir als Anbieter der Webseite keine
            Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung
            durch Facebook erhalten. Nähere Informationen zur Erhebung und
            Nutzung der Daten durch Facebook sowie über Ihre diesbezüglichen
            Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in
            den Datenschutzhinweisen von Facebook unter
            https://www.facebook.com/about/privacy/. Wir selbst geben keine
            Kundendaten an Facebook weiter. Weiterführende Informationen über
            Ihre entsprechenden Datenschutzrechte und Einstellungsmöglichkeiten
            zum Schutz Ihrer Privatsphäre können Sie einsehen unter:
            <br />
            <a
              href="https://www.facebook.com/policy.php"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.facebook.com/policy.php
            </a>
            <br />
            <a
              href="https://www.facebook.com/help/186325668085084"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.facebook.com/help/186325668085084
            </a>
            .
          </section>
          <section>
            <h3>6. Ihre Rechte</h3>
            Sie haben sowohl als Nutzer dieser Internetseite als auch als Kunde
            im Rahmen unserer betrieblichen Tätigkeit mit der Verarbeitung
            personenbezogener Daten folgende Betroffenenrechte nach der DSGVO:
            <ul>
              <li> das Recht auf Auskunft nach Artikel 15 DSGVO,</li>
              <li>das Recht auf Berichtigung nach Artikel 16 DSGVO,</li>
              <li>das Recht auf Löschung nach Artikel 17 DSGVO,</li>
              <li>
                das Recht auf Einschränkung der Verarbeitung nach Artikel 18
                DSGVO,
              </li>
              <li>das Recht auf Datenübertragbarkeit nach Artikel 20 DSGVO,</li>
              <li>das Widerspruchsrecht nach Artikel 21 DSGVO,</li>
              <li>
                das Recht auf Widerruf einer erteilten Einwilligung gem. Artikel
                7 Abs. 3 DSGVO. Sie haben jederzeit mit Wirkung für die Zukunft
                das Recht eine einmal erteilte Einwilligung zu widerrufen, ohne
                dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum
                Widerruf erfolgten Verarbeitung hierdurch berührt wird.
              </li>
              <li>
                das Recht auf Beschwerde bei einer Aufsichtsbehörde nach Artikel
                77 DSGVO.
              </li>
            </ul>
          </section>
          <section>
            <h3>7. Ihre Rechte als von der Datenverarbeitung Betroffener:</h3>
            Ihr Vertrauen ist uns wichtig. Daher möchten wir Ihnen jederzeit
            Rede und Antwort bezüglich der Verarbeitung Ihrer personenbezogenen
            Daten stehen.Rechtsgrundlage für die Verarbeitung Ihrer Daten ist
            Artikel 6 Absatz 1 Satz 1 DSGVO. Sollten Sie Fragen haben, können
            Sie sich gern an uns wenden.
          </section>
          <section>
            <h3>Änderung unserer Datenschutzbestimmungen</h3>
            Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit
            sie stets den aktuellen rechtlichen Anforderungen entspricht oder um
            Änderungen unserer Leistungen in der Datenschutzerklärung
            umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren
            erneuten Besuch gilt dann die neue Datenschutzerklärung.
          </section>
        </div>
      </div>

      {children}
    </Col>
  );
}

export default memo(Privacy);
