/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Image } from 'react-bootstrap';

import Brush from 'assets/brushes/appointment-blue.png';
import styles from './HoursSection.module.scss';

function HoursSection() {
  return (
    <div className={styles.container}>
      <h1>sprechstunden</h1>

      <p>
        <span>09:00 - 13:00</span>
        mo
        <span>14:00 - 19:00</span>
      </p>

      <p>
        <span>09:00 - 13:00</span>
        di
        <span>14:00 - 17:00</span>
      </p>

      <p>
        <span>08:00 - 14:00</span>
        mi
        <span className={styles.hidden}>13:00 - 14:00</span>
      </p>

      <p>
        <span>09:00 - 13:00</span>
        do
        <span>14:00 - 19:00</span>
      </p>

      <p>
        <span>08:00 - 14:00</span>
        fr
        <span className={styles.hidden}>13:00 - 14:00</span>
      </p>
      <p>Samstags nach Vereinbarung</p>

      <Image
        fluid
        src={Brush}
        className={styles.brush}
        alt="Appointment brush"
      />
    </div>
  );
}

export default memo(HoursSection);
