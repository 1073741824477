import React, { memo } from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import useResizeObserver from 'common/hooks/useResizeObserver';

import ServicesSection from 'components/desktop/services-section/ServicesSection';
import LogoSection from 'components/desktop/logo-section/LogoSection';
import TeamSection from 'components/desktop/team-section/TeamSection';
import AdvicesSection from 'components/desktop/advices-section/AdvicesSection';
import ContactSection from 'components/desktop/contact-section/ContactSection';
import AppointmentSection from 'components/desktop/appointment-section/AppointmentSection';
import StatusSection from 'components/desktop/status-section/StatusSection';
import HoursSection from 'components/desktop/hours-section/HoursSection';
import Drawer from 'components/desktop/drawer/Drawer';

import styles from './Desktop.module.scss';

function Desktop() {
  // team width will be taken as a reference to align all elements on the left
  const [leftElement, leftWidth] = useResizeObserver();

  // logo width will be taken as a reference to align all elements on the right
  const [rightElement, rightWidth] = useResizeObserver();

  return (
    <>
      <Drawer width={leftWidth} />
      <Container fluid className={styles.container}>
        <Row className={styles.first}>
          <Col md={7} className={styles.services}>
            <ServicesSection width={leftWidth} />
          </Col>
          <Col md={5} className={styles.logo}>
            <LogoSection ref={rightElement} />
          </Col>
        </Row>
        <Row className={styles.second}>
          <Col md={7} className={styles.team}>
            <TeamSection ref={leftElement} width={leftWidth} />
          </Col>
          <Col md={5} className={styles.appointment}>
            <AppointmentSection width={rightWidth} />
          </Col>
        </Row>
        <Row className={styles.third}>
          <Col md={7} className={styles.advices}>
            <AdvicesSection width={leftWidth} />
          </Col>
          <Col md={5} className={styles.status}>
            <StatusSection width={rightWidth} />
          </Col>
        </Row>
        <Row className={styles.fourth}>
          <Col md={7} className={styles.contact}>
            <ContactSection width={leftWidth} />
          </Col>
          <Col md={5} className={styles.hours}>
            <HoursSection width={rightWidth} detailsWidth={leftWidth} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default memo(Desktop);
